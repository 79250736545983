import bind from "bind-decorator";
import { IGroupSignaling } from "../base/iMsSignaling";
import { MsClient } from "../base/msClient";
import { PresenceService } from "../../rabbitmq/presenceService";
import { Logger } from "../../../helpers/logger";
import { RemoteEndpoint, RemoteEndpointType } from "./remoteEndpoint";
import { MediaTrack } from "./mediaTrack";
import { LocalEndpoint } from "./localEndpoint";
import { ProducerInfo } from "../base/producerInfo";
import { action, autorun, computed, IReactionDisposer, makeObservable, observable, ObservableMap, runInAction } from "mobx";
import { localEndpointState } from "../../state/localEndpointState";
import assert from "assert";

enum DataTypeLabel {
    KEYBOARD = 1,
    MOUSE = 2,
    TOUCH = 3,
    CLIPBOARD = 4,
    VMCMD = 5,
    EVDEV = 6,
    GPS = 7
}

export enum KeyEventType {
    KEYDOWN = 0,
    KEYUP = 1,
    KEYPRESSED = 2,
};

export interface IEmulator {
    sendKeyboard(opts: { key?: string, keyCode?: number, eventType: KeyEventType }): void;
    sendMouse(event: any, buttons: number): void;
    sendTouches(touching: React.TouchList, left: React.TouchList, event: React.TouchEvent<HTMLElement>): void;
}

export enum ScreenState {
    OFF = 0,
    ON = 1
}

enum CameraState {
    OFF = 0,
    ON = 1
}

export enum GpsState {
    OFF = 0,
    ON = 1
}

export class SmartphoneState {

    // SCREEN
    @observable private _screenState: ScreenState = ScreenState.ON

    @computed get screenState(): ScreenState {
        return this._screenState
    }

    set screenState(val: ScreenState) {
        runInAction(() => this._screenState = val)
    }

    // GPS
    @observable private _gpsState: GpsState = GpsState.OFF
    @computed get gpsState(): GpsState {
        return this._gpsState;
    }
    set gpsState(val: GpsState) {
        runInAction(() => this._gpsState = val)
    }

    // CAMERA

    @observable frontCameraState: CameraState = CameraState.OFF
    @observable backCameraState: CameraState = CameraState.OFF

    constructor() {
        makeObservable(this)
    }
}

export class EmulatorRemoteEndpoint extends RemoteEndpoint implements IEmulator {
    
    public static readonly SLEEP = 'sleep'
    public static readonly WAKE_UP = 'wake_up'

    protected name2ProducerInfo = new ObservableMap<string, ProducerInfo[]>();
    private handlingUserMedia: boolean = false;
    private screenTouchProducerInfo: ProducerInfo | undefined;
    @observable public showSettings: boolean = false;
    @observable public smartphoneState = new SmartphoneState();
    disposers = new Array<IReactionDisposer>();
    pauseConsumersTimer: ReturnType<typeof setInterval> | null = null;
    
    @computed get displayIsOff(): boolean {
        return this.smartphoneState.screenState == ScreenState.OFF
    };

    @computed get displayIsOn(): boolean {
        return this.smartphoneState.screenState == ScreenState.ON
    };

    @computed get anyWebcamIsOn(): boolean {
        return this.smartphoneState.backCameraState === CameraState.ON || this.smartphoneState.frontCameraState === CameraState.ON
    }

    @computed get anyMicrophoneIsOn(): boolean {
        // dummy
        return false
    }

    @computed get producingAnyWebcam(): boolean {
        const x = Array.from(this.name2ProducerInfo.values())
        return x.some(i => i.some(v => v.kind === 'video'))
    }

    @computed get producingAnyMicrophone(): boolean {
        const x = Array.from(this.name2ProducerInfo.values())
        return x.some(i => i.some(v => v.kind === 'audio'))
    }

    @computed get producingAnyDevice(): boolean {
        return this.producingAnyMicrophone || this.producingAnyWebcam
    }

    public get type(): RemoteEndpointType {
        return 'emubridge'
    }
    
    protected async startSendingMedia(sendTracks?: MediaTrack[]): Promise<ProducerInfo[]> {
        const newProducerInfos = await this.localEndpoint.produceMedia({ mediaTracks: sendTracks, opts: { opusDtx: true } });
        return newProducerInfos
    }

    protected async replaceTrack(producerInfo: ProducerInfo, newMediaTrack: MediaTrack | null): Promise<void> {
        const prevTrack = producerInfo.mediaTrack
        try {
            const res = await this.localEndpoint.replaceTrack(producerInfo.id, newMediaTrack)
            producerInfo.mediaTrack = newMediaTrack
            prevTrack?.track.stop();
            return res
        } catch {
            this.logger.error('Could not replace track of producer')
        }
        return new Promise<void>((resolve, reject) => reject())
    }

    public stopSendingMedia() {
        const mediaId = Array.from(this.name2ProducerInfo.keys());
        mediaId.forEach(mId => this.stopSending(mId));
    }    

    protected stopProducers(producerInfos: ProducerInfo[]) {
        if (producerInfos && producerInfos.length > 0) {
            this.localEndpoint.stop(producerInfos);
            this.stopMediaTracks(producerInfos.map(pi => pi.mediaTrack))
        } else {
            this.logger.warn('stopMedia(): cannot stop because there are no producerInfos')
        }
    }

    protected stopMediaTracks(mediaTracks: (MediaTrack | null)[]) {
        if (mediaTracks) {
            mediaTracks.forEach(mt => mt?.track.stop())
        }
    }

    /* MICROPHONE */

    protected async startMicrophone(microphoneId: string, deviceId?: string) {
        if (this.handlingUserMedia) {
            setTimeout(() => {
                this.startMicrophone(microphoneId, deviceId)
            }, 2000);
            return
        }                
        const activeMicrophone = this.name2ProducerInfo.get(microphoneId)
        if (!activeMicrophone) {
            this.handlingUserMedia = true
            try {

                // const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                const stream = await navigator.mediaDevices.getUserMedia({ audio: {
                    deviceId,
                } });
                const microphoneTrack = stream.getAudioTracks()[0];
                const mediaTrack = new MediaTrack(microphoneTrack, microphoneId)
                const microphoneProducers = await this.startSendingMedia([mediaTrack]);
                runInAction(() => this.name2ProducerInfo.set(microphoneId, microphoneProducers))
            } catch (e: any) {
                this.logger.error('An error occured requesting and starting microphone', e.toString())
                if (deviceId) {
                    // try again without specifying the deviceId
                    setTimeout(() => this.startMicrophone(microphoneId, undefined), 10)
                }
            } finally {
                this.handlingUserMedia = false
            }

        } else {
            // already producing microphone, maybe we should send the id?
        }
    }

    protected async replaceMicrophoneDevice(microphoneId: string, deviceId: string | undefined) {
        if (this.handlingUserMedia) {
            setTimeout(() => {
                this.replaceMicrophoneDevice(microphoneId, deviceId)
            }, 2000);
            return
        }                
        const activeMicrophone = this.name2ProducerInfo.get(microphoneId)
        if (!activeMicrophone) {
            this.logger.warn('Trying to replace microphone %s but it is not active', microphoneId);
            return
        }

        if (deviceId) {
            try {
                this.handlingUserMedia = true
                // const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                const stream = await navigator.mediaDevices.getUserMedia({ audio: {
                    deviceId,
                } });
                const microphoneTrack = stream.getAudioTracks()[0];
                const mediaTrack = new MediaTrack(microphoneTrack, microphoneId)
                assert(activeMicrophone.length === 1)
                assert(activeMicrophone[0].kind === microphoneTrack.kind)
                await this.replaceTrack(activeMicrophone[0], mediaTrack)
            } catch (e: any) {
                this.logger.error('An error occured requesting and starting microphone', e.toString())
            } finally {
                this.handlingUserMedia = false
            }
        } else {
            // no microphone attached, send nothing
            await this.replaceTrack(activeMicrophone[0], null)
        }
    }


    protected stopSending(mediaId: string) {
        if (this.handlingUserMedia) {
            setTimeout(() => {
                this.stopSending(mediaId)
            }, 2000);
            return
        }
        const producerInfos = this.name2ProducerInfo.get(mediaId)

        if (producerInfos) {
            try {

                this.handlingUserMedia = true;
                
                this.stopProducers(producerInfos)
                this.name2ProducerInfo.delete(mediaId)
            } finally {
                this.handlingUserMedia = false;
            }
        } else {
            this.logger.warn(`Trying to stop media ${mediaId} but it is not active`)
        }
    }

    /* All Emu events (v1) should end up here */
    @bind
    private async onEmuEvent(senderId: string, data: any) {
        this.logger.debug('onEmuEvent', senderId, data)
        if (data.sender === "camera") {
            data.webcamid = getWebcamId(data.device_id)
            await this.emuEvent_handleCameraRequest(data)
        }
        if (data.sender === "screen") {
            this.smartphoneState.screenState = data.event
        }
        if (data.sender === "gnss") {
            this.smartphoneState.gpsState = data.event
        }
        if (data.sender === "app") {
            await this.emuEvent_AppRequest(data)
        }
    }

    @bind
    emuEvent_AppRequest(data: any) {
        this.emit("showSettings");
    }
    
    @bind
    async emuEvent_handleCameraRequest(data: any) {
        if (data.event === CameraState.ON) {
            await this.startWebcam(data.webcamid, localEndpointState.videoInputDeviceId)
        } else 
        if (data.event === CameraState.OFF) {
            await this.stopWebcam(data.webcamid)
        }
    }

    @bind
    private async onMicrophoneOpen(senderId: string, data: any) {
        this.logger.debug('onMicrophoneOpen', senderId, data)
        await this.startMicrophone(data.microphoneId);
    }

    @bind
    private onMicrophoneClose(senderId: string, data: any) {
        this.logger.debug('onMicrophoneClose', senderId, data)
        this.stopSending(data.microphoneId);
    }

    /* WEBCAM */
    @bind
    private async onWebcamOpen(senderId: string, data: any) {
        this.logger.debug('onWebcamOpen', data)
        data.webcamid = getWebcamId(data.device_id)
        await this.startWebcam(data.webcamid, localEndpointState.videoInputDeviceId)
    }

    @bind
    private onWebcamClose(senderId: string, data: any) {
        this.logger.debug('onWebcamClose', data)
        data.webcamid = getWebcamId(data.device_id)
        this.stopWebcam(data.webcamid)
    }

    protected async startWebcam(webcamId: string, deviceId?: string | undefined, width: number = 480, height = 480) {
        if (this.handlingUserMedia) {
            setTimeout(() => {
                this.startWebcam(webcamId, deviceId, width, height)
            }, 2000);
            return
        }

        const activeWebcam = this.name2ProducerInfo.get(webcamId)

        if (!activeWebcam) {
            this.handlingUserMedia = true
            try {
                const stream = await (navigator.mediaDevices as any).getUserMedia({ video: {
                    // WxH ideally so we don't need to crop/scale on server side
                    width: { ideal: width },
                    height: { ideal: height },
                    facingMode: { ideal: webcamId },
                    resizeMode: 'crop-and-scale',
                    deviceId: deviceId,
                } });
                const webcamTrack = stream.getVideoTracks()[0];
                const webcamMediaTrack = new MediaTrack(webcamTrack, webcamId);
                const webcamProducers = await this.startSendingMedia([webcamMediaTrack])
                runInAction(() => this.name2ProducerInfo.set(webcamId, webcamProducers))
            } catch (e: any) {
                this.logger.error('An error occured requesting and starting webcam media', e.toString())
                if (deviceId) {
                    // try again without specifying the deviceId
                    setTimeout(() => this.startWebcam(webcamId, undefined, width, height), 10)
                }
            } finally {
                this.handlingUserMedia = false
            }
        } else {
            // throw new Error('Unhandled case')
            // already producing this webcam, maybe we should send the id?
        }
    }

    protected stopWebcam(webcamId: string) {
        if (this.handlingUserMedia) {
            setTimeout(() => {
                this.stopWebcam(webcamId)
            }, 2000);
            return
        }
        const webcamProducerInfos = this.name2ProducerInfo.get(webcamId)
        if (webcamProducerInfos) {
            try {
                this.handlingUserMedia = true;
                this.stopProducers(webcamProducerInfos)
                this.name2ProducerInfo.delete(webcamId)
            } finally {
                this.handlingUserMedia = false;
            }
        }
        else {
            console.warn('Trying to close a camera that is not open: ', webcamId)
        }
    }

    protected async replaceWebcam(webcamId: string, deviceId?: string | undefined, width: number = 480, height = 480) {
        if (this.handlingUserMedia) {
            setTimeout(() => {
                this.replaceWebcam(webcamId, deviceId, width, height)
            }, 2000);
            return
        }

        const activeWebcam = this.name2ProducerInfo.get(webcamId)

        if (!activeWebcam) {
            this.logger.warn('Trying to replace webcam %s but it is not active', webcamId);
            return
        }
        assert(activeWebcam.length === 1)

        if (deviceId) {
            try {
                this.handlingUserMedia = true
                const stream = await (navigator.mediaDevices as any).getUserMedia({ video: {
                    // WxH ideally so we don't need to crop/scale on server side
                    width: { ideal: width },
                    height: { ideal: height },
                    facingMode: { ideal: webcamId },
                    resizeMode: 'crop-and-scale',
                    deviceId: deviceId,
                } });
                const webcamTrack = stream.getVideoTracks()[0];
                const webcamMediaTrack = new MediaTrack(webcamTrack, webcamId);
                assert(activeWebcam[0].kind === webcamTrack.kind)
                await this.replaceTrack(activeWebcam[0], webcamMediaTrack)
            } catch (e: any) {
                this.logger.error('An error occured requesting and starting webcam media for replace track', e.toString())
            } finally {
                this.handlingUserMedia = false
            }
        } else {
            await this.replaceTrack(activeWebcam[0], null)
        }
    }    

    protected registerEvents(groupSignaling: IGroupSignaling) {
        groupSignaling.on([ ...this.aid, 'openmicrophone'], this.onMicrophoneOpen)
        groupSignaling.on([ ...this.aid, 'closemicrophone'], this.onMicrophoneClose)
        groupSignaling.on([ ...this.aid, 'openwebcam'], this.onWebcamOpen)
        groupSignaling.on([ ...this.aid, 'closewebcam'], this.onWebcamClose)
        groupSignaling.on([ ...this.aid, 'emuEvent'], this.onEmuEvent)
    }

    protected unregisterEvents(groupSignaling: IGroupSignaling) {
        groupSignaling.off([...this.aid, 'openmicrophone'], this.onMicrophoneOpen)
        groupSignaling.off([...this.aid, 'closemicrophone'], this.onMicrophoneClose)
        groupSignaling.off([...this.aid, 'openwebcam'], this.onMicrophoneOpen)
        groupSignaling.off([...this.aid, 'closewebcam'], this.onMicrophoneClose)
        groupSignaling.on([ ...this.aid, 'emuEvent'], this.onEmuEvent)
    }
    
    constructor(public readonly id: string,
        // public readonly sharedData: any,
        // public readonly producers: ProducerInfo[],
        protected readonly msClient: MsClient,
        protected readonly groupSignaling: IGroupSignaling,
        protected readonly presenceService: PresenceService,
        protected readonly localEndpoint: LocalEndpoint,
        public autoConsumeProducers: boolean = true) {
            super(id, msClient, groupSignaling, presenceService, autoConsumeProducers)
            makeObservable(this)
            this.logger = new Logger(`EmuRemoteEndpoint_${id}`)
            this.registerEvents(this.groupSignaling)
            this.localEndpoint.onConnectedAsync(async () => {
                this.logger.debug(`onConnectedAsync`)
                this.screenTouchProducerInfo = (await this.localEndpoint.produceMedia({data: { name: 'screen touch'}}))[0]
                // this.localEndpoint.produceDefaultMedia(false, false, true)
            })
            // this.pstnSignaling = new PstnSignaling2(groupSignaling)
            // // this.groupSignaling.on(['*', '*', EVENT_CALL_OPERATION], this.onCallOp)
            // this.groupSignaling.on(['*', '*', EVENT_CALL_STATUS], this.onCallStatus)
            // this.smsSignaler = new SmsSignaling2(this.groupSignaling, this.phoneNumber)
            // this.smsSignaler.on(EVENT_SMS, this.onSms)
            // this.smsSignaler.on(EVENT_SMSSTATUS, this.onSmsStatus)
            // this.disposers.push(autorun(() => {
            //     if (this.smartphoneState.screenState === ScreenState.OFF) {
            //         this.pauseConsumersTimer = setTimeout(() => {
            //             this.msClient.pauseConsumers()
            //             this.pauseConsumersTimer = null
            //         }, 7000)
            //     } else if (this.smartphoneState.screenState === ScreenState.ON) {
            //         if (this.pauseConsumersTimer) {
            //             clearTimeout(this.pauseConsumersTimer)
            //             this.pauseConsumersTimer = null
            //         }
            //         this.msClient.resumeConsumers()
            //     }
            // }))
        }

    // from IEmulator

    @bind
    public sendKeyboard(opts: { key?: string, keyCode?: number, eventType: KeyEventType }) {
        if (!this.screenTouchProducerInfo) return;
        (opts as any)["label"] = DataTypeLabel.KEYBOARD
        this.localEndpoint.sendData([opts], this.screenTouchProducerInfo.id)
    }

    @bind
    public sendEvDev(keyCode: number, eventType: KeyEventType) {
        if (!this.screenTouchProducerInfo) return;
        
        this.localEndpoint.sendData([{ label: DataTypeLabel.EVDEV, keyCode, eventType }], this.screenTouchProducerInfo.id)
    }

    @bind
    public sendGps(position: GeolocationPosition) {
        if (!this.screenTouchProducerInfo) return;

        let x = {
            accuracy: position.coords.accuracy,
            altitude: position.coords.altitude,
            altitudeAccuracy: position.coords.altitudeAccuracy,
            heading: position.coords.heading,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            speed: position.coords.speed
        }

        this.logger.debug('sending gps', position)
        this.localEndpoint.sendData([{ label: DataTypeLabel.GPS, position: x }], this.screenTouchProducerInfo.id)
    }
    
    @bind
    public sendTouches(touching: React.TouchList, left: React.TouchList, event: React.TouchEvent<HTMLElement>) {

        if (!this.screenTouchProducerInfo) return;

        const bounds = event.currentTarget.getBoundingClientRect()
        var scalingX = 1080 / bounds.width
        var scalingY = 1920 / bounds.height

        const touches: any[] = [];

        for (var i = 0; i < touching.length; i++) {
            if (touching[i].identifier === 0) {
                const x = Math.round((touching[i].clientX - bounds.left) * scalingX)
                const y = Math.round((touching[i].clientY - bounds.top) * scalingY)

                var pressure = 1
                // try {
                //     pressure = (touching[i] as any).force
                // } catch {}

                touches.push({ x: x, y: y, pressure: pressure, identifier: touching[i].identifier })
            }
        }

        for (var i = 0; i < left.length; i++) {
            if (left[i].identifier === 0) {
                const x = Math.round((left[i].clientX - bounds.left) * scalingX)
                const y = Math.round((left[i].clientY - bounds.top) * scalingY)
                touches.push({ x: x, y: y, pressure: 0, identifier: left[i].identifier }) // we need to send a pressure 0 to end the touch
            }
        }

        if (touches.length > 0)
            this.localEndpoint.sendData([{ label: DataTypeLabel.TOUCH, touches: touches }])
    }

    @bind 
    public sendMouse(event: any, buttons: number) {
        if (!this.screenTouchProducerInfo) return;
        // event.persist()
        const bounds = event.target.getBoundingClientRect()
        var scalingX = 1080 / bounds.width
        var scalingY = 1920 / bounds.height
        const x = (event.clientX - bounds.left) * scalingX
        const y = (event.clientY - bounds.top) * scalingY

        this.localEndpoint.sendData([{ label: DataTypeLabel.MOUSE, x: Math.round(x), y: Math.round(y), buttons: buttons }], this.screenTouchProducerInfo.id)
    }

    @bind sendClipboard(text: string) {
        if (!this.screenTouchProducerInfo) return;
        this.localEndpoint.sendData([{ label: DataTypeLabel.CLIPBOARD, text: text}], this.screenTouchProducerInfo.id)
    }

    @bind send_manualCommand(command: Object) {
        this.groupSignaling.send(RemoteEndpoint.MANUAL_COMMAND, command)
    }

    @bind send(cmd: string) {
        const command = {
            endpointId: this.id,
            command: cmd,
            producerId: '*'
        }
        this.send_manualCommand(command)        
    }

    @bind send_getVmState() {
        this.localEndpoint.sendData([{ label: DataTypeLabel.VMCMD }])
    }

    @bind wakeUp(): void {
        const command = {
            endpointId: this.id,
            command: EmulatorRemoteEndpoint.WAKE_UP,
        }
        this.send_manualCommand(command)
    }

    public override close(): void {
        super.close()
        this.logger.debug('closing')
        this.unregisterEvents(this.groupSignaling)
        // stop all microphones if needed
        this.stopSendingMedia();
        this.disposers.forEach(d => d())
    }
}

function getWebcamId(device_id: any): string {
    if (device_id == "0") return "environment";
    if (device_id == "1") return "user";
    throw new Error("unknown camera");
}
