import Button from "@mui/material/Button/Button";
import bind from "bind-decorator";
import React from "react";
import { RemoteEndpoint } from "../lib/msClient/endpoints/remoteEndpoint";

export interface IRemoteEndpointProps {
    endpoint: RemoteEndpoint
}

export class RemoteEndpointComponent extends React.Component<IRemoteEndpointProps> {
    
    _canvas = React.createRef<HTMLCanvasElement>();
    _video: HTMLVideoElement;
    _mediaStream: MediaStream | undefined;
    _lastDisplayedTimestamp: DOMHighResTimeStamp = 0;
    _canvas2dContext: CanvasRenderingContext2D | null = null
    _imgBox = React.createRef<HTMLDivElement>()
    _idx: any
    _stopped: boolean = false

    constructor(public readonly props: IRemoteEndpointProps){
        super(props)
        this._video = document.createElement("video")
        this._video.autoplay = false
        this._video.muted = true
    }
    
    async componentDidMount() {
        try {
            this.setupCanvas()
            this.startMedia()
        } catch (e) {
            console.error('Error', e)
        }
        window.addEventListener('beforeunload', this.cleanup);
    }
    
    componentWillUnmount() {
        this.cleanup()
    }
    
    @bind
    private cleanup() {
        this._stopped = true
        this.props.endpoint.stopAllElements()
        window.removeEventListener('beforeunload', this.cleanup); // remove the event handler for normal unmounting
    }
    
    private setupCanvas() {
        if (this._canvas.current) {
            this._canvas2dContext = this._canvas.current.getContext("2d")
        }
    }
    
    private async startMedia() {
        this.props.endpoint.on(RemoteEndpoint.TRACKS, this.onTracks)
        const currentTracks = this.props.endpoint.getAllTracks()
        this.onTracks(currentTracks)
    }
    
    @bind
    private onTracks(tracks: MediaStreamTrack[]) {
        console.log('received tracks', tracks.length)
        if (tracks.length > 0) {
            this._mediaStream = new MediaStream(tracks)
            this._video.srcObject = this._mediaStream
            this._video.play().catch(console.error)
            window.requestAnimationFrame(this.requestAnimationFrame);
        } else {
            this._video.srcObject = null
        }
    }
    
    @bind
    private requestAnimationFrame(timestamp: DOMHighResTimeStamp) {
        
        if (!this._stopped && this._lastDisplayedTimestamp + 1000/30 <= timestamp) {
            this._canvas2dContext!.drawImage(this._video, 0, 0, this._canvas.current!.width, this._canvas.current!.height)
            this._lastDisplayedTimestamp = timestamp
        }
        !this._stopped && window.requestAnimationFrame(this.requestAnimationFrame)
    }
    
    @bind 
    private onData(...msg: any[]){
        console.log('onData()', msg)
    }
    
    @bind switchMute() {
        if (this._video) {
          if (this._video.muted) {
            console.debug('unmutting')
            this._video.muted = !this._video.muted
          }
        }
      }

    render() {
        return (<React.Fragment key={this.props.endpoint.id}>
            <div>
                <div ref = { this._imgBox} >
                    <canvas id = {`videoCanvas_${this.props.endpoint.id}`} key={`videoCanvas_${this.props.endpoint.id}`} ref= {this._canvas }></canvas>
                </div>
                <div>
                    <Button onClick={this.switchMute}>Switch mute</Button>
                </div>
                <div>
                    <p>Remote endpoint</p>
                </div>
            </div>
            </React.Fragment>)
        }
    }