import Button from "@mui/material/Button";
import TouchAppRoundedIcon from '@mui/icons-material/TouchAppRounded';
import bind from "bind-decorator";
import { autorun, computed, IReactionDisposer, makeAutoObservable, makeObservable, observable, reaction, runInAction, when } from "mobx";
import React from "react";
import { EmulatorRemoteEndpoint, GpsState, KeyEventType, ScreenState, SmartphoneState } from "../lib/msClient/endpoints/emulatorRemoteEndpoint";
import { RemoteEndpoint } from "../lib/msClient/endpoints/remoteEndpoint";
import { Alert, Backdrop, IconButton, Snackbar, Stack, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { observer } from 'mobx-react';
import { Logger } from "../helpers/logger";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { EmulatorRemoteEndpoint2 } from "../lib/msClient/endpoints/emulatorRemoteEndpoint2";
import { localEndpointState } from "../lib/state/localEndpointState";
import { UserSettingTab } from "./userSettings";
import { ProcessState } from "../lib/state/emuHardwareState";
import { once } from "mobx/dist/internal";

export interface IRemoteEndpointProps {
    endpoint: EmulatorRemoteEndpoint2
    openSettingsLink: React.ReactNode
}

enum RemoteEndpointError {
    NO_ERROR = 0,
    CANNOT_CONSUME = 1
}

@observer
export class EmulatorRemoteEndpointComponent extends React.Component<IRemoteEndpointProps> {
    
    _canvas = React.createRef<HTMLCanvasElement>();
    _video: HTMLVideoElement;
    @observable _mediaStream: MediaStream | undefined;
    _lastDisplayedTimestamp: DOMHighResTimeStamp = 0;
    _canvas2dContext: CanvasRenderingContext2D | null = null
    _imgBox = React.createRef<HTMLDivElement>()
    _mobileOffScreen = React.createRef<HTMLImageElement>()
    
    _idx: any
    _stopped: boolean = false
    isMouseButtonDown: boolean = false;
    @observable userHasInteracted: boolean = false;
    private logger: Logger;
    disposers = new Array<IReactionDisposer>();
    @observable endpoint: EmulatorRemoteEndpoint2;
    pausingTimer: ReturnType<typeof setInterval> | null = null;
    animationFrameRequest: number = 0;
    gpsHandle: number = 0;
    @observable remoteEndpointError: RemoteEndpointError = RemoteEndpointError.NO_ERROR
    @observable showWebcamError: boolean = false;
    @observable showMicError: boolean = false;
    @observable _videoPlayError: DOMException | null = null;

    @computed get videoPlayError(): DOMException | null {
        return this._videoPlayError
    }

    set videoPlayError(e: DOMException | null) {
        runInAction(() => this._videoPlayError = e)
    }

    @computed get hasError(): boolean {
        return this.remoteEndpointError !== RemoteEndpointError.NO_ERROR || this.videoPlayError !== null
    }

    @observable private _showWebcamInUseAlert = true
    @observable private _showMicInUseAlert = true
    @observable private _showUsingMediaInputDevicesAlert = true

    @computed get showWebcamInUseAlert(): boolean {
        return this._showWebcamInUseAlert
    }

    set showWebcamInUseAlert(v: boolean) {
        runInAction(() => this._showWebcamInUseAlert = v)
    }

    @computed get showMicInUseAlert(): boolean {
        return this._showMicInUseAlert
    }

    set showMicInUseAlert(v: boolean) {
        runInAction(() => this._showMicInUseAlert = v)
    }

    @computed get showUsingMediaInputDevicesAlert(): boolean {
        return this._showUsingMediaInputDevicesAlert
    }

    set showUsingMediaInputDevicesAlert(v: boolean) {
        runInAction(() => this._showUsingMediaInputDevicesAlert = v)
    }

    constructor(public readonly props: IRemoteEndpointProps){
        super(props)
        makeObservable(this)
        this._video = document.createElement("video")
        this._video.autoplay = false
        this._video.muted = true
        window.addEventListener('resize', this.reportWindowSize2)
        this.logger = new Logger(`EmulatorRemoteEndpointComponent_${props.endpoint.id}`)
        this.endpoint = props.endpoint

    }
    
    /* MOUSE */
    @bind
    onMouseDown(event: any) {
        this.isMouseButtonDown = true
        this.props.endpoint.sendMouse(event, 1)
    }
    
    @bind
    onMouseUp(event: any) {
        if (this.isMouseButtonDown) {
            this.isMouseButtonDown = false;
            this.props.endpoint.sendMouse(event, 0)
        }
    }
    
    @bind
    onMouseMove(event: any) {
        if (this.isMouseButtonDown) {
            this.props.endpoint.sendMouse(event, 1)
        }
    }
    
    @bind
    onMouseEnter(event: any) {
    }
    
    @bind
    onMouseLeave(event: any) {
        if (this.isMouseButtonDown) {
            this.isMouseButtonDown = false;
            this.props.endpoint.sendMouse(event, 0)
        }
    }
    
    /* Touch */
    @bind onTouchStart(event: any) {
        
        // if (this._fullscreen && screenfull.isEnabled && this._touchscreen.current) {
        //   screenfull.request(this._touchscreen.current)
        // }
        
        event.preventDefault()
        event.stopPropagation()    // event.persist()
        
        this.props.endpoint.sendTouches(event.targetTouches, { length: 0 } as React.TouchList, event)
    }
    
    @bind onTouchMove(event: any) {
        event.preventDefault()
        event.stopPropagation()
        
        this.props.endpoint.sendTouches(event.targetTouches, { length: 0 } as React.TouchList, event)
    }
    
    @bind onTouchEnd(event: any) {
        event.preventDefault()
        event.stopPropagation()    // event.persist()
        this.props.endpoint.sendTouches(event.targetTouches, event.changedTouches, event)
    }
    
    @bind onTouchCancel(event: any) {
        event.preventDefault()
        event.stopPropagation()    // event.persist()
        this.props.endpoint.sendTouches(event.targetTouches, event.changedTouches, event)
    }
    
    @bind onKeyDown(event: KeyboardEvent) {
        this.logger.debug('onKeyDown', event)
        if (event.key && isKeyAllowed(event)) {
            event.preventDefault()
            this.props.endpoint.sendKeyboard({ key: event.key, eventType: KeyEventType.KEYDOWN })
        }
    }
    
    @bind onKeyUp(event: KeyboardEvent) {
        this.logger.debug('onKeyUp', event)
        if (event.key && isKeyAllowed(event)) {
            event.preventDefault()
            this.props.endpoint.sendKeyboard({ key: event.key, eventType: KeyEventType.KEYUP})
        }
    }
    
    @bind onKeyPress(event: KeyboardEvent) {
        this.logger.debug('onKeyPress', event)
        if (event.key && isKeyAllowed(event)) {
            this.props.endpoint.sendKeyboard({ key: event.key, eventType: KeyEventType.KEYPRESSED} )
        }
    }

    @bind onPaste(event: ClipboardEvent) {
        this.logger.debug('onPaste', event)
        if (event.clipboardData) {
            event.preventDefault();
            let text = event.clipboardData.getData('text');
            this.props.endpoint.sendClipboard(text);
        }
    }
    
    @bind
    private attach(endpoint: EmulatorRemoteEndpoint2, _canvas: React.RefObject<HTMLCanvasElement>) {
        if (_canvas.current) {
            _canvas.current.onmousedown = this.onMouseDown
            _canvas.current.onmouseenter = this.onMouseEnter
            _canvas.current.onmouseleave = this.onMouseLeave
            _canvas.current.onmousemove = this.onMouseMove
            _canvas.current.onmouseup = this.onMouseUp
            _canvas.current.ontouchstart = this.onTouchStart
            _canvas.current.ontouchcancel = this.onTouchCancel
            _canvas.current.ontouchend = this.onTouchEnd
            _canvas.current.ontouchmove = this.onTouchMove
            _canvas.current.onkeydown = this.onKeyDown
            _canvas.current.onkeyup = this.onKeyUp
            _canvas.current.onkeypress = this.onKeyPress
            _canvas.current.onpaste = this.onPaste
            _canvas.current.tabIndex = 1000
            _canvas.current.style.outline = "none";
            // const ctx =  _canvas.current.getContext('2d');
            // if (ctx) ctx.filter = 'blur(4px)';
        }
    }
    
    componentDidMount() {
        autorun(() => {
            const videoEl = this._video as any;
            if (videoEl && videoEl.setSinkId) {
                videoEl.setSinkId(localEndpointState.audioOutputDevice?.deviceId)
            }
        })
        reaction(() => this.endpoint.producingAnyMicrophone,
            (value, prev, reaction) => {
                if (!value) {
                    this.showMicInUseAlert = true
                }
            })
        reaction(() => this.endpoint.producingAnyWebcam,
            (value, prev, reaction) => {
                if (!value) {
                    this.showWebcamInUseAlert = true
                }
            })
        reaction(() => this.endpoint.producingAnyDevice,
            (value, prev, reaction) => {
                if (!value) {
                    this.showUsingMediaInputDevicesAlert = true
                }
            })
        reaction(() => this.endpoint.producingAnyWebcam && !localEndpointState.videoInputDevice,
            (value, prev, reaction) => {
                this.showWebcamError = value
            })
        reaction(() => this.endpoint.producingAnyMicrophone && !localEndpointState.audioInputDevice,
            (value, prev, reaction) => {
                this.showMicError = value
            })
        try {

            this.setupCanvas()
            this.startMedia()
        } catch (e) {
            this.logger.error('Error', e)
        }
    }
    
    componentWillUnmount() {
        this.cleanup()
    }
    
    @bind
    private cleanup() {
        this._stopped = true
        this.runDisposers();
    }

    @bind
    private runDisposers() {
        
        this.disposers?.forEach(d => d())
    }
    
    private setupCanvas() {
        if (this._canvas.current) {
            this._canvas2dContext = this._canvas.current.getContext("2d")
            this._idx = setTimeout(this.reportWindowSize2, 300)
        }
    }
    
    private async startMedia() {
        this.props.endpoint.on(RemoteEndpoint.ERROR, this.onError)
        this.props.endpoint.on(RemoteEndpoint.TRACKS, this.onTracks)
        const currentTracks = this.props.endpoint.getAllTracks()
        this.onTracks(currentTracks)
    }
    
    @bind
    private onTracks(tracks: MediaStreamTrack[]) {
        this.logger.debug('received tracks', tracks.length)
        // at the minimum, we want the screen and the audio produced by the emu
        if (tracks.length >= 2) { 
            runInAction(() =>  {
                this._mediaStream = new MediaStream(tracks)
                this._video.srcObject = this._mediaStream
            })

            this.disposers.forEach(d => d())
            this.disposers = new Array<IReactionDisposer>();
            // Handle screen
            this.disposers.push(autorun(async () => {
                    if (this.endpoint.displayIsOff) {
                        if (!this._video.paused) {
                            this.logger.debug('pausing video in 3500ms')
                            this.pausingTimer = setTimeout(() => {
                                this.logger.debug('pausing video now')
                                if (this.animationFrameRequest !== 0) {
                                    window.cancelAnimationFrame(this.animationFrameRequest)
                                    this.animationFrameRequest = 0
                                }
                                this._video.pause()
                                this.drawScreenOff()
                                this.pausingTimer = null
                            }, 3500)
                        }
                    }
                    if (this.endpoint.displayIsOn) {
                        if (this.pausingTimer) {
                            clearTimeout(this.pausingTimer)
                            this.pausingTimer = null
                        }
                        if (this._video.paused) {
                            this.disposers.push(
                                when(() => this.userHasInteracted, 
                                async() => {
                                    try {
                                        this.logger.debug('resuming video');
                                        await this._video.play();
                                        this.videoPlayError = null;
                                        this.animationFrameRequest = window.requestAnimationFrame(this.onAnimationFrame);
                                    } catch (e: any) {
                                        this.logger.error("Error occured when trying to show phone screen", e, e.name);
                                        this.videoPlayError = e;
                                        // if (typeof e === typeof DOMException && e.name === 'NotSupportedError') {
                                        //     return
                                        // }
                                    }
                                })
                            )
                        }
                    }
                }))
            
            // Handle GPS
            // this.disposers.push(autorun(async ()=> {
            //         if (this.endpoint.smartphoneState.gpsState === GpsState.OFF) {
            //             if (this.gpsHandle !== 0) {
            //                 navigator.geolocation.clearWatch(this.gpsHandle)
            //                 this.gpsHandle = 0
            //             }
            //         }
            //         if (this.endpoint.smartphoneState.gpsState === GpsState.ON) {
            //             // sometimes the emu requests the gps even if the screen is off - don't know why right now, which might confuse the user
            //             if (this.endpoint.smartphoneState.screenState === ScreenState.ON) {
            //                 this.gpsHandle = navigator.geolocation.watchPosition((position) => {
            //                     this.logger.debug('Gps got position', position)
            //                     this.props.endpoint.sendGps(position);
            //                 }, (error) => {
            //                     this.logger.error('Gps position error', error)
            //                     // on error send the position of the Kapellbrücke in Lucerne
            //                     this.props.endpoint.sendGps({
            //                         timestamp: Date.now(),
            //                         coords: {
            //                             latitude: 47.05181180789558,
            //                             longitude: 8.307563290503847,
            //                             accuracy: 100,
            //                             altitude: null,
            //                             altitudeAccuracy: null,
            //                             heading: null,
            //                             speed: null
            //                         }
            //                     })
            //                 }, 
            //                 { 
            //                     enableHighAccuracy: true,
            //                     maximumAge: 30000,
            //                 });
            //             }
            //         }
            //     }))
        } else {
            this._video.srcObject = null
            this.videoPlayError = null
            this.runDisposers()
            if (this.pausingTimer) {
                clearTimeout(this.pausingTimer)
                this.pausingTimer = null
            }
            if (this.animationFrameRequest) {
                window.cancelAnimationFrame(this.animationFrameRequest)
                this.animationFrameRequest = 0
            }
        }
    }

    @bind
    private onError(error: { message: string, mei: any }) {
        runInAction(() => {
            this.logger.debug("Cannot consume", error)
            this.remoteEndpointError = RemoteEndpointError.CANNOT_CONSUME
        })
    }

    
    @bind
    private drawScreenOff() {
        if (this._canvas.current) {
            this._canvas2dContext!.fillStyle = "black";
            this._canvas2dContext!.fillRect(0, 0, this._canvas.current.width, this._canvas.current.height);
            // this._canvas2dContext!.fillStyle = "white";
            // this._canvas2dContext!.font = '20px sans-serif';

            // var textString = "Sleeping ...",
            // textWidth = this._canvas2dContext!.measureText(textString ).width;

            // this._canvas2dContext!.fillText(textString , (this._canvas.current.width/2) - (textWidth / 2), this._canvas.current.height / 3);
        }
    }
    
    @bind
    private onAnimationFrame(timestamp: DOMHighResTimeStamp) {
        if (!this._stopped && this._lastDisplayedTimestamp + 1000/30 <= timestamp && !this._video.paused) {
            if (this._canvas.current) {
                this._canvas2dContext!.drawImage(this._video, 0, 0, this._canvas.current!.width, this._canvas.current!.height)
                this._lastDisplayedTimestamp = timestamp
            }
        }
        if (!this._stopped && !this._video.paused) {
            this.animationFrameRequest = window.requestAnimationFrame(this.onAnimationFrame)
        } else {
            this.animationFrameRequest = 0
        }
    }

    @bind
    private reportWindowSize2() {
        clearInterval(this._idx)
        if (this._canvas.current) {
            // this._canvas.current.width = window.innerWidth;
            this._canvas.current.height = window.innerHeight;
            this._canvas.current.width = this._canvas.current.height * (1080/1920)
            if (this.endpoint.displayIsOff) {
                this.drawScreenOff()
            }
        }
    }
    
    // @bind 
    // private reportWindowSize() {
    //     clearInterval(this._idx)
    //     if (this._mobileOffScreen && this._mobileOffScreen.current && this._imgBox && this._imgBox.current) {
    //         const mobileOffPos = this._mobileOffScreen.current!.getBoundingClientRect()
    //         const parentPos = this._imgBox.current!.getBoundingClientRect()
            
    //         if (this._canvas.current) {
    //             this._canvas.current.style.top = mobileOffPos.top - parentPos.top + "px"
    //             this._canvas.current.style.bottom = mobileOffPos.bottom + "px"
    //             this._canvas.current.style.left = mobileOffPos.left - parentPos.left + "px"
    //             this._canvas.current.style.right = mobileOffPos.right + "px"
    //             this._canvas.current.style.width = mobileOffPos.width + "px"
    //             this._canvas.current.style.height = mobileOffPos.height + "px"
    //             this._canvas.current.width = mobileOffPos.width
    //             this._canvas.current.height = mobileOffPos.height
    //             // this._divOverlay.current.positiion = clientRect.height + "px"
    //             // this._divOverlay.current.style.height = clientRect.height + "px"
    //         }
    //     }
    // }
    
    @bind 
    private onData(...msg: any[]){
        this.logger.debug('onData()', msg)
    }
    
    @bind unmute() {
        if (this._video) {
            if (this._video.muted) {
                this.logger.debug('unmutting')
                this._video.muted = false
            }
        }
    }
    
    @bind
    showEmu() {
        this.attach(this.props.endpoint, this._canvas)
        runInAction(() => {
            this.userHasInteracted = true
        })
        
        this.unmute()
    }

    @bind
    refreshPage() {
        window.location.reload()
    }    


    @bind
    renderScreenIsOff(): React.ReactNode {
        return (<>
            <Backdrop sx={{ cursor: "grab" }} open={ !this.hasError && this.userHasInteracted && this.endpoint.displayIsOff } onClick={() => this.endpoint.wakeUp()}>
                <Stack alignItems="center" spacing={2} >
                    <PowerSettingsNewIcon color="primary" style={{ fontSize: 90 }} ></PowerSettingsNewIcon>
                    <Typography color="white" >Your phone is sleeping</Typography>
                    { this.props.openSettingsLink }
                </Stack>
            </Backdrop>
        </>)
    }

    
    @bind
    renderUserInteractionRequired(): React.ReactNode {
        return (<>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={ this._mediaStream !== undefined && !this.userHasInteracted && this.endpoint.remoteEndpointState.hardwareState.process.state === ProcessState.RUNNING}
        >
            <Stack alignItems="center" spacing={2} >
                <Button color="secondary" variant="contained" size="large" onClick={this.showEmu} endIcon={<TouchAppRoundedIcon style={{ fontSize: 30 }} />}>Connect</Button>
                { this.props.openSettingsLink }
            </Stack>
        </Backdrop>
        </>)
    }
    
    @bind
    renderHasError(): React.ReactNode {
        return (<>
            <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={ this.userHasInteracted && this.hasError }
            >
                <Stack sx={{ minWidth: '20%', maxWidth: '80%' }} spacing={2}>
                    <Typography>Error: codec not supported</Typography>
                    <Typography>Can you try a different browser?</Typography>
                    <Button color="secondary" variant="contained" size="large" onClick={this.refreshPage} endIcon={<TouchAppRoundedIcon style={{ fontSize: 30 }} />}>Refresh</Button>
                    { this.props.openSettingsLink }
                </Stack>
            </Backdrop>
        </>)
    }

    @bind
    renderWebcamInUseAlert(): React.ReactNode {
        if (localEndpointState.videoInputDevice) {
            return <Snackbar
            action={<Button onClick={() => {
                this.showWebcamInUseAlert = false; 
                localEndpointState.userSettings.selectedTab = UserSettingTab.MediaSettings;
                localEndpointState.userSettings.show = true 
            }} >
                Change
            </Button>}
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, cursor: 'default' }}
            onClick={() => this.showWebcamInUseAlert = false}
            open={this.endpoint.producingAnyWebcam && this.showWebcamInUseAlert} autoHideDuration={6000}
            onClose={() => this.showWebcamInUseAlert = false} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            message={`${localEndpointState.videoInputDevice.label} webcam is being used.`}/>
        } else {
            this.logger.warn("No video input device", localEndpointState.videoInputDevice)
            return null
        }
    }    

    @bind
    renderMicrophoneInUseAlert(): React.ReactNode {
        if (localEndpointState.audioInputDevice) {
            return <Snackbar
            action={<Button onClick={() => {
                this.showMicInUseAlert = false; 
                localEndpointState.userSettings.selectedTab = UserSettingTab.MediaSettings;
                localEndpointState.userSettings.show = true 
            }} >
                Change
            </Button>}
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, cursor: 'default' }}
            onClick={() => this.showMicInUseAlert = false}
            open={this.endpoint.producingAnyMicrophone && this.showMicInUseAlert} autoHideDuration={6000}
            onClose={() => this.showMicInUseAlert = false} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            message={`${localEndpointState.audioInputDevice.label} microphone is being used.`}/>
        } else {
            this.logger.warn("Could not get label of microphone in use", localEndpointState.audioInputDevice)
            return null
        }
    }

    @bind
    renderLostDeviceConnectionAlert(): React.ReactNode {
        return <Snackbar
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, cursor: 'default' }}
        open={this.showMicError} autoHideDuration={6000}
        onClose={() => { this.showMicError = false }} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <Alert variant="filled" severity="error">
            Lost connection to your microphone
            </Alert>
        </Snackbar>
    }    

    @bind
    renderLostWebcamConnectionAlert(): React.ReactNode {
        return <Snackbar
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, cursor: 'default' }}
        onClick={() => {}}
        open={this.showWebcamError} autoHideDuration={6000}
        onClose={() => {this.showWebcamError = false}} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <Alert variant="filled" severity="error" onClose={() => {this.showWebcamError = false}}>
            Lost connection to your webcam
            </Alert>
        </Snackbar>
    }    

    @bind
    renderUsingMediaInputDevicesAlert(): React.ReactNode {
        let microphoneText: string = ''
        if (this.endpoint.producingAnyMicrophone) {
            if (localEndpointState.audioInputDevice) {
                microphoneText = `"${localEndpointState.audioInputDevice.label}" (microphone)`
            }
        }
        let webcamText: string = ''
        if (this.endpoint.producingAnyWebcam) {
            if (localEndpointState.videoInputDevice) {
                webcamText = `"${localEndpointState.videoInputDevice.label}" (webcam)`
            }
        }
        const handleClose = () => this.showUsingMediaInputDevicesAlert = false

        if (microphoneText.length || webcamText.length) {
            const message = `Using device(s): ${microphoneText} ${webcamText}`
            return <Snackbar
            action={
                <>
                    <Button onClick={() => {
                        localEndpointState.userSettings.showTab(UserSettingTab.MediaSettings);
                        handleClose()
                    }} >
                        Change
                    </Button>
                    <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={handleClose}
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
                </>
            }
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, cursor: 'default' }}
            open={this.showUsingMediaInputDevicesAlert} autoHideDuration={6000}
            onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            message={message}/>
        } else {
            this.logger.warn("Could not get text(s) of input devices in use", localEndpointState.audioInputDevice, localEndpointState.videoInputDevice)
            return null
        }
    }    

    render() {
        return (<React.Fragment key={this.props.endpoint.id}>
            <canvas id = "videoCanvas" ref= {this._canvas} className="center"/>
            { !this.hasError && this.renderScreenIsOff() }
            { this.renderUserInteractionRequired() }
            { this.renderHasError() }
            { !this.hasError && this.endpoint.producingAnyDevice && this.renderUsingMediaInputDevicesAlert() }
            { !this.hasError && this.renderLostDeviceConnectionAlert()}
            { !this.hasError && this.renderLostWebcamConnectionAlert()}
            </React.Fragment>)
        }
    }

function isKeyAllowed(event: KeyboardEvent) {
    if (event.ctrlKey || event.metaKey) return false;
    return event.key.length == 1 
        || event.key === "Backspace" 
        || event.key === "Delete"
        || event.key === "Enter"
        || event.key === "Escape";
}
